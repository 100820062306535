<template>
    <v-container>
        <v-row justify="center" class=" pa-5">
            <v-col class="text-center">
                 <h1 class="text-h3 font-weight-light">Terms and Conditions</h1>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-3">
            <h3 class="text-body-2 font-weight-light">2 April 2020</h3>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" sm="10" md="10" lg="8" xl="8">
                <div class="text-body-2">
                    <p>
                        These terms and conditions govern your use of the products and services of Liberty Infrasystems LLC ("we", "us", "our") including but not limited to the website, network applications and their programming interfaces, desktop and mobile applications, and customer support services (collectively the "Service"). By accessing or using the Service you agree to all of these terms and conditions.
                    </p>
                    <p>
                        Liberty Infrasystems reserves the right to modify these terms and conditions at any time. When we make such modifications, we will announce the changes on our site and attempt to notify all registered users via email. Your continued use of the Service after any such changes constitutes your acceptance of the new terms and conditions. If you do not agree to these terms or to any future modifications, do not use or access the Service.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">The Service</h2>
                    <p>
                        Eligibility. You may not use the Service if you are a minor and do not have parental consent.
                    </p>
                    <p>
                        Registration Data. You agree to provide accurate, current, and complete information when prompted ("Registration Data"). You agree to keep your Registration Data accurate, current, and complete by notifying us of changes or completing a new registration.
                    </p>
                    <p>
                        User Content. You are solely responsible for the content you send or receive through the Service ("User Content") and for any consequences of posting or publishing it, and you agree that the Service is only acting as a passive conduit for your online distribution and publication of your User Content. You understand and agree it is not possible for the Service to "unsend" any User Content sent on your behalf to any other party as part of your use of the Service.
                    </p>
                    <p>
                        Liability. Liberty Infrasystems will not be liable for any losses caused by any authorized or unauthorized use or non-use of the Service.
                    </p>
                    <p>
                        Fees. Service charges apply in accordance with the published fees that were in effect on the date of your registration. If you complete a new registration, Service charges apply for any subsequent use of the Service in accordance with the published fees that were in effect on the date of your new registration. Additional fees may apply for Internet access to use the Service. Taxes may apply to your use of the Service. You are solely responsible for paying such other charges or taxes in connection with your use of the Service. Your access to the Service may be suspended or cancelled if you do not make your payment on time and in full.
                    </p>
                    <p>
                        Changes. We may change, stop providing certain features of, or create usage limits for the Service without prior notice.
                    </p>
                    <p>
                        Termination. We reserve the right to temporarily or permanently suspend or terminate access to the Service without notice and liability at any time. Upon termination, you continue to be bound by this agreement.
                    </p>
                    <p>
                        Rules. You agree to not engage in any of the following prohibited activities: (a) copying, distributing, or disclosing any part of the Service in any medium, including without limitation by any automated or non-automated means; (b) attempting to interfere with, compromise integrity or security, or decipher any transmissions to or from any aspect of the Service; (c) taking any action that may impose, at our sole discretion, an unreasonable or disproportionately large load on our infrastructure; (d) collecting or harvesting any personally identifiable information, including account names, from the service; (e) impersonating another person, conducting fraud, or attempting to hide your identity; (f) accessing any content on the Service through any means other than those authorized by Liberty Infrasystems; (g) bypassing our measures to prevent or restrict access to the Service.
                    </p>
                    <p>
                        The Service may contain links to third-party websites, apps, services, or offers that are not owned or controlled by us. We do not endorse or assume any responsibility for any such third-party content, products, or services. If you access a third party service from the Service, you do so at your own risk, and you understand that this agreement and our privacy policy do not apply to your use of those third-party services.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">License</h2>
                    <p>
                        Subject to the terms of and conditions of this agreement, you are hereby granted a non-exclusive, limited, non-transferable, revocable license to use the Service for your personal or commercial use as permitted by the features of the Service. Liberty Infrasystems reserves all rights not expressly granted herein. We may terminate or revoke this license at any time.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Proprietary Rights</h2>
                    <p>
                        Except for your Registration Data and User Content, Liberty Infrasystems and its licensors retain exclusive ownership and control over the Service and all materials therein, including software, images, texts, and graphics. Except for the limited license granted to you, we retain all rights, title, and interests including all Intellectual Property rights in the Service. If you choose to submit any comments or ideas about the Service ("Feedback"), you agree that your disclosure is gratuitous, unsolicited, and without restriction and will not place Liberty Infrasystems under any fiduciary or other obligation, and that we may use or disclose the Feedback without any further consent from you and without any compensation. You further acknowledge that, by accepting your submitted Feedback, Liberty Infrasystems does not waive any rights to use similar or related ideas previously known to us or obtained elsewhere.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Indemnity</h2>
                    <p>
                        You agree to defend, indemnify, and hold harmless Liberty Infrasystems and its employees from and against any and all claims arising from your use of and access to the Service, including any violation of this agreement.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">No Warranty</h2>
                    <p>
                        The Service is provided on an "as is" and "as available" basis. Use of the Service is at your own risk. To the maximum extent permitted by applicable law, the Service is provided without warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                    </p>

                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Limitation of Liability</h2>
                    <p>
                        To the maximum extent permitted by applicable law, in no event shall Liberty Infrasystems or its employees be liable for any damages that result from the use of, or inability to use, this Service. Under no circumstances will Liberty Infrasystems be responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Service, Registration Data, User Content, or other information contained therein, and in no event will our total cumulative liability under this agreement exceed $1,000.
                    </p>
                    <p>
                        The Service is controlled and operated from its facilities in the United States. Liberty Infrasystems makes no representations that the Service is appropriate for use in locations other than the United States. Those who access or use the Service from other jurisdictions do so at their own volition and are entirely responsible for compliance with all applicable United States and local laws and regulations, including but not limited to export and import regulations. You may not use the Service if you are a resident of a country embargoed by the United States, or if you are a foreign person or entity blocked or denied by the United States government. Unless otherwise explicitly stated, all materials found on the Service are solely directed to individuals, companies, or other entities located in the United States.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">General</h2>
                    <p>
                        Governing law. You agree that the Service shall be demed solely based in and governed by the laws of the State of Oregon, and that the Service shall be deemed a passive one that does not give rise to personal jurisdiction over Liberty Infrasystems in jurisdictions other than Oregon. Any claim or dispute between you and Liberty Infrasystems that arises from the Service shall be decided exclusively by a court of competent jurisdiction located in Washington County, Oregon, unless submitted to arbitrary as described below.
                    </p>
                    <p>
                        Arbitration. For any claim under this agreement where the total amount of the award sought is less than $10,000, the party requesting relief may elect to resolve the dispute through binding non-appearance-based arbitration. The party electing such arbitration shall initiate the arbitration through an established alternative dispute resolution ("ADR") provider mutually agreed upon by both parties. The ADR provider and the parties must comply with the following rules: (a) the arbitration shall be conducted by telephone, online, or be solely based on written submissions, as selected by the party initiating the arbitration; (b) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and (c) any judgement on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                    </p>
                    <p>
                        Severability. This agreement, together with any admendments and any additional agreements you may enter into with Liberty Infrasystems in connection with the Service, shall constitute the entire agreement between you and Liberty Infrasystems concerning the Service. If any provision of this agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this agreement, which shall remain in full force and effect.
                    </p>
                    <p>
                    No waiver. No waiver of any term of this agreement shall be deemed a further or continuing waiver of any other term, and our failure to assert any right or provision under this agreement shall not constitute a waiver of such right or provision.
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
* {
    line-height: 2.5
}
</style>

<script>
export default {
    components: {
    //
    },
};
</script>
